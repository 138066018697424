<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <ManageHeader />
      <v-row>
        <v-col v-for="(item, i) in links" :key="i" cols="6" md="4">
          <v-card
            class="py-15"
            :style="item.style"
            @click="goToPage(item.url)"
          >
            <v-card-text class="text-center py-10">
              <v-overlay
                absolute
                opacity=".8"
              >
                <h1>
                  {{ item.title }}
                </h1>
              </v-overlay>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-lazy>
</template>

<script>
  import ManageHeader from '@/views/manage/Header'
  export default {
    name: 'Manage',
    components: { ManageHeader },
    data () {
      return {
        links: [
          {
            title: '사용자관리',
            url: '/manage/users/1',
            style: {
              backgroundImage: `url(${require('@/assets/images/manageUserImage.jpg')})`,
              backgroundSize: 'cover',
            },
          },
          {
            title: '업체관리',
            url: '/manage/company/1',
            style: {
              backgroundImage: `url(${require('@/assets/images/companyImage.jpg')})`,
              backgroundSize: 'cover',
            },
          },
          {
            title: '견적서 관리',
            url: '/manage/estimate/1',
            style: {
              backgroundImage: `url(${require('@/assets/images/estimateImage.jpg')})`,
              backgroundSize: 'cover',
            },
          },
          {
            title: '신규상품관리',
            url: '/manage/product/1',
            style: {
              backgroundImage: `url(${require('@/assets/images/wareHouseImage.jpg')})`,
              backgroundSize: 'cover',
            },
          },
          {
            title: '승인내역관리',
            url: '/manage/history/1',
            style: {
              backgroundImage: `url(${require('@/assets/images/withdrawImage.jpg')})`,
              backgroundSize: 'cover',
            },
          },
          {
            title: '출금요청관리',
            url: '/manage/withdraw/1',
            style: {
              backgroundImage: `url(${require('@/assets/images/withdrawImage.jpg')})`,
              backgroundSize: 'cover',
            },
          },
          {
            title: '상품신청관리',
            url: '/manage/product/registration/1',
            style: {
              backgroundImage: `url(${require('@/assets/images/registrationImage.jpg')})`,
              backgroundSize: 'cover',
            },
          },
          {
            title: '관리자설정',
            url: '/manage/admin/1',
            style: {
              backgroundImage: `url(${require('@/assets/images/interviewImage.jpg')})`,
              backgroundSize: 'cover',
            },
          },
          {
            title: '팝업 관리',
            url: '/manage/popup/1',
            style: {
              backgroundImage: `url(${require('@/assets/images/popupManageImage.jpg')})`,
              backgroundSize: 'cover',
            },
          },
          {
            title: '사이트설정',
            url: '/manage/site',
            style: {
              backgroundImage: `url(${require('@/assets/images/cogsImage.jpg')})`,
              backgroundSize: 'cover',
            },
          },
        ],
      }
    },
    methods: {
      goToPage (url) {
        this.$router.push(url)
      },
    },
  }
</script>

<style scoped>

</style>
